/**

=========================================================
** Page Title Exports - Styles can be edit on @stylesheet - styles/core/components/footer
=========================================================

**/

export * from "./PageTitle";
export * from "./Title";
