import { Layout } from "components/Layout";
import SEO from "components/SEO";
import { SectionContainer } from "components/Section";
import { PageTitle } from "components/Title";
import { useRouter } from "next/router";

export default function Page404() {
  const router = useRouter();
  return (
    <Layout>
      <SEO
        title="Handyman - Konstrukcje drewniane, zadaszenia wiaty tarasy | Stargard | Szczecin"
        description=""
        link={router.pathname}
      />
      <div className="main-wrapper bg-transparent relative z-10 pb-20 pt-40 ">
      <SectionContainer className="components--container wrap wrap-px grid gap-8 sm:gap-24">
        <PageTitle>Coś poszło nie tak... :(</PageTitle>
        <div className="text-center">
          <p className="text-2xl font-semibold">404</p>
          <p className="text-xl">Nie znaleziono strony</p>
        </div>
        </SectionContainer>
      </div>
    </Layout>
  );
}